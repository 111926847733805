<script>
	import { dayjs } from '@woltair/translations-package-fe'
	import { t } from '@woltair/translations-package-fe'
	import { INSTALLATION_CATEGORY, REGIONS, getAttrFromConst } from '$lib/consts'
	import { HEAT_PUMP, PHOTOVOLTAICS } from '$lib/routes'
	import ConditionalAnchor from '../ConditionalAnchor.svelte'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	export let title = {
		text: t('references.thumbnails.title'),
		textAlign: 'center'
	}
	export let references = []
	export let referencesOnlyImages = false
	export let showTitle = false
	export let background = ''
	export let titleFontSize = ''
	export let titleWeight = ''
	export let stretchDirection = 'both' //both, left, right, none
	export let enableSlideButtons = false

	let offsetCls = ''

	let container
	let thumbWrapper
	let thumbnail

	if (stretchDirection == 'left') {
		offsetCls = 'scroll-offset-left'
	} else if (stretchDirection == 'none') {
		offsetCls = 'no-offset'
	} else if (stretchDirection == 'right') {
		offsetCls = 'scroll-offset-right'
	} else {
		offsetCls = 'scroll-offset-x'
	}

	const DEVICE_URL = {
		[INSTALLATION_CATEGORY.TEPELNE_CERPADLO]: HEAT_PUMP,
		[INSTALLATION_CATEGORY.FOTOVOLTAIKA]: PHOTOVOLTAICS
	}

	const getReferenceURL = (ref) => {
		const category = ref.order.installationOrder?.category
		const region = ref.order.location?.address?.region

		return DEVICE_URL?.[category] && region && PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_DE'
			? '/' +
					DEVICE_URL[category] +
					'/' +
					getAttrFromConst(REGIONS.WOLTAIR_CZ, 'value', ref.order.location?.address.region, 'url') +
					'#ref' +
					ref.order.uid
			: null
	}

	const scrollSlide = (direction) => {
		let { width } = thumbWrapper.getBoundingClientRect()

		width = width / references.length

		if (direction == 'next') {
			container.scrollBy({
				left: width,
				behavior: 'smooth'
			})
		} else if (direction == 'prev') {
			container.scrollBy({
				left: -width,
				behavior: 'smooth'
			})
		}
	}

	const resizeImage = (url) => {
		const imageWidth = 232 //in px
		const imageURL = new URL(url)
		if (imageURL.host === 'lh3.googleusercontent.com') return `${imageURL.href}=w${imageWidth}`
		else return url
	}
</script>

<div class="relative {background}">
	<div class="page-wrapper flex flex-col items-start justify-between lg:flex-row lg:items-center">
		{#if title.text && showTitle}
			<h3 class="mb-2 text-{titleFontSize} font-{titleWeight} text-{title.textAlign}">
				{title.text}
			</h3>
		{/if}
		{#if enableSlideButtons}
			<div class="buttons-wrapper mb-6 mt-3 md:mb-0">
				<button
					class="mr-3 aspect-square w-[13vw] rotate-90
      bg-[url('/images/icons/gray/arrow_square_transparent.svg')] bg-contain
      bg-no-repeat md:w-12"
					on:click={() => scrollSlide('prev')}
				/>
				<button
					class="bg-no-repeat) aspect-square w-[13vw] -rotate-90
      bg-[url('/images/icons/gray/arrow_square_transparent.svg')] bg-contain md:w-12"
					on:click={() => scrollSlide('next')}
				/>
			</div>
		{/if}
	</div>
	<div class="hide-scrollbar {offsetCls} flex w-full overflow-x-auto" bind:this={container}>
		<div class="flex w-fit gap-4" bind:this={thumbWrapper}>
			{#each references as reference}
				{@const src = reference.resources[0]?.url}
				{@const refURL = getReferenceURL(reference)}
				{#if src}
					<ConditionalAnchor
						condition={refURL && PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ'}
						href={refURL}
					>
						<div
							bind:this={thumbnail}
							class="thumbnail min-h-w-56 my-5 block w-64 shrink-0 bg-white"
							class:thumbnail-shadow={refURL && PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ'}
						>
							<div class="m-0 h-40 w-full">
								<img
									src={resizeImage(src)}
									alt="Produkt-{reference.order.uid}"
									class="block h-full w-full object-cover"
								/>
							</div>
							<div class="my-3 overflow-hidden">
								<p
									class="unstyled mb-0 mt-0 whitespace-nowrap text-left text-base font-medium text-neutral-800"
								>
									<!-- TODO: Delete the PUBLIC_WOLTAIR_WEB_CODE condition once DE references will be ready -->
									{#if PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_DE' && PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_IT'}
										{reference?.city || reference.order?.location?.address?.city || 'ČR'}
									{/if}
								</p>
								{#if !referencesOnlyImages}
									<div class="container-flex">
										<p class="unstyled text-gray my-0 text-xs font-light">
											{dayjs(reference?.createdAt).fromNow()}
										</p>
									</div>
								{/if}
							</div>
						</div>
					</ConditionalAnchor>
				{/if}
			{/each}
		</div>
	</div>
</div>

<style lang="scss">
	.thumbnail-shadow {
		transition: 0.2s box-shadow;
		&:hover {
			box-shadow: 0px 2px 20px 0 rgba(185, 185, 185, 0.7);
		}
	}
	.thumbnail {
		padding: 0.75rem 0.75rem 0.75rem 0.75rem;
	}
	.scroll-offset-right {
		padding-right: calc((100vw - 1360px) / 2 + 1rem);
	}

	.scroll-offset-left {
		padding-left: calc((100vw - 1360px) / 2 + 1rem);
	}

	.scroll-offset-x {
		padding-left: 1rem;
		padding-right: 1rem;

		@screen lg {
			padding-left: calc((100vw - 1360px) / 2 + 1rem);
			padding-right: calc((100vw - 1360px) / 2 + 1rem);
		}
	}
	.no-offset {
		padding-left: 1em;
		padding-right: 1em;
	}
</style>
